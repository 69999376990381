.page-header {
  @include clearfix;

  max-width: 1400px;
  margin: 0 auto;
  padding: 26px 20px;
}

.logo {
  float: left;
  width: 23.1%;

  @include tablet {
    float: none;
    width: auto;
    margin: 0 auto;
    display: block;
    max-width: 100%;
  }

}

.partners {
  position: relative;
  float: right;
  width: 50%;
  height: 0;
  padding-bottom: 11.5%;
  margin-top: 6%;

  img {
    float: left;
    margin: 0 10px;
    position: absolute;
  }

  .partner-1 {
    width: 22%;
    margin-top: 10px;
    left: 0;
  }

  .partner-2 {
    margin-top: 15px;
    left: 25%;
    width: 20%;
  }

  .partner-3 {
    left: 44%;
    width: 16%;
  }

  .partner-4 {
    margin-top: 6px;
    right: 0;
    width: 22%;
  }

  .partner-5 {
    left: 0;
    bottom: 0;
    width: 21%;
  }

  .partner-6 {
    left: 23%;
    bottom: 0;
    width: 19%;
  }

  .partner-7 {
    right: 18%;
    bottom: 5px;
    width: 16%;
  }

  .partner-8 {
    right: 0;
    bottom: 11px;
    width: 14%;
  }

  @include tablet {
    float: none;
    width: 100%;
    padding-bottom: 22.5%;
    margin-top: 20px;
  }

}


.section {
  padding: 60px 0;
  background-color: $gray;
  text-align: center;
  min-height: 100vh;
  position: relative;

  .inner-wrapper {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(20%);
    opacity: 0;
    transition: all 0.8s;

    &.show {
      transform: translateY(-50%);
      opacity: 1;
    }

  }

  &.video-wrapper {
    min-height: auto;

    .inner-wrapper {
      position: static;
      transform: none;
    }

  }

  p {
    @include font-size(24px);
  }

  &:nth-child(even) {
    background-color: $blue;
    color: $white;
  }

  @include tablet {
    min-height: 80vh;

    p {
      @include font-size(20px);
    }

  }

  @include mobile {
    padding: 40px 0;

    p {
      @include font-size(16px);
    }

  }

}


.is-splash.flowplayer .fp-ui, .is-paused.flowplayer .fp-ui {
  background-image: none;
}

.flowplayer .fp-progress {
  background-color: $blue;
}

.flowplayer .fp-volumelevel {
  background-color: $blue;
}