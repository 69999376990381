
@mixin mobile() {

  @media only screen and (max-width: 640px) {
    @content;
  }

}

@mixin tablet {

  @media only screen and (max-width: 900px) {
    @content;
  }

}

@mixin desktop {

  @media only screen and (max-width: 1920px) and (min-width: 769px) {
    @content;
  }

}

@mixin max-width($bp) {

  @media only screen and (max-width: $bp) {
    @content;
  }

}

@mixin min-width($bp) {

  @media only screen and (min-width: $bp) {
    @content;
  }

}


/*

	For styling the placeholder

*/

@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}
}
/*

	For styling the placeholder focus

*/

@mixin placeholder-focus {
  &:focus::-webkit-input-placeholder {@content}
  &:focus:-moz-placeholder           {@content}
  &:focus::-moz-placeholder          {@content}
  &:focus:-ms-input-placeholder      {@content}
}

@mixin clearfix {

  &::after {
    content: "";
    clear: both;
    display: table;
  }

}

/*

	Replaces text with img.

*/

@mixin replace-text($img-url) {
  text-indent: -9999px;
  background-image: url($img-url);
  white-space: nowrap;
}

/*

	Horizontal list. Can be used for navigation.

*/

@mixin list-horizontal {
  list-style-type:none;
  padding:0;
  margin:0;

  > li{
    display:block;
    float:left;
  }

}


/*

  Rem font size

*/

@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

/*

  Calc vw for 640px

*/
@function get-vw($target) {
  $vw-context: (640 * 0.01) * 1px;
  @return ($target / $vw-context) * 1vw;
}

@function get-vw-desktop($target) {
  $vw-context: (1920 * 0.01) * 1px;
  @return ($target / $vw-context) * 1vw;
}


@function percent-width($item-width, $content-width) {
  @return ($item-width / $content-width) * 100%;
}

@function calc-margin($width, $items) {
  @return (100 - ($width * $items)) / ($items - 1);
}

@mixin item-layout($items, $item-width, $prev: 'false') {
  $items1: $items + 1;
  $items2: $items + 2;
  float: left;
  width: $item-width;
  margin-right: calc-margin($item-width, $items);
  @if($prev == 'true') {

    &:nth-of-type(#{$items1}n + #{$items1}) {
      margin-right: calc-margin($item-width, $items);
    }

    &:nth-of-type(#{$items1}n + #{$items2}) {
      clear: none;
    }
  }

  &:nth-of-type(#{$items}n + #{$items}) {
    margin-right: 0;
  }

  &:nth-of-type(#{$items}n + #{$items1}) {
    clear: both;
  }

}
