html {
  box-sizing: border-box;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

a {
  color: inherit;
}

input,
textarea {
  border-radius: 0;
}

input[type=submit] {
  cursor: pointer;
}

:focus {
  outline: none;
}

body {
  @include font-size(18px);

  line-height: 1.2;
  color: $text-color;
  font-family: $font-base;
  min-height: 100vh;
  position: relative;
  -webkit-font-smoothing: antialiased;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

.inner-wrapper {
  @include clearfix;

  max-width: 1200px;
  padding: 0 80px;
  margin: 0 auto;

  @include mobile {
    padding: 0 20px;
  }

}